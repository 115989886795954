/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CouponBrand from '../model/CouponBrand';
import CouponBrandUpdateValues from '../model/CouponBrandUpdateValues';
import CouponCompany from '../model/CouponCompany';
import CouponCompanyUpdateValues from '../model/CouponCompanyUpdateValues';
import CouponDetail from '../model/CouponDetail';
import CouponSummary from '../model/CouponSummary';
import CouponUpdateValues from '../model/CouponUpdateValues';
import NewCoupon from '../model/NewCoupon';
import NewCouponBrand from '../model/NewCouponBrand';
import NewCouponCompany from '../model/NewCouponCompany';

/**
* Coupon service.
* @module api/CouponApi
* @version 1.1.70
*/
export default class CouponApi {

    /**
    * Constructs a new CouponApi. 
    * @alias module:api/CouponApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * クーポンの削除
     * @param {Number} couponId クーポンの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteCouponWithHttpInfo(couponId) {
      let postBody = null;

      // verify the required parameter 'couponId' is set
      if (couponId === undefined || couponId === null) {
        throw new Error("Missing the required parameter 'couponId' when calling deleteCoupon");
      }


      let pathParams = {
        'couponId': couponId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/coupons/{couponId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポンの削除
     * @param {Number} couponId クーポンの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteCoupon(couponId) {
      return this.deleteCouponWithHttpInfo(couponId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供ブランドの削除
     * @param {Number} couponBrandId クーポン提供ブランドの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteCouponBrandWithHttpInfo(couponBrandId) {
      let postBody = null;

      // verify the required parameter 'couponBrandId' is set
      if (couponBrandId === undefined || couponBrandId === null) {
        throw new Error("Missing the required parameter 'couponBrandId' when calling deleteCouponBrand");
      }


      let pathParams = {
        'couponBrandId': couponBrandId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/couponBrands/{couponBrandId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供ブランドの削除
     * @param {Number} couponBrandId クーポン提供ブランドの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteCouponBrand(couponBrandId) {
      return this.deleteCouponBrandWithHttpInfo(couponBrandId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供企業の削除
     * @param {Number} couponCompanyId クーポン提供企業の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteCouponCompanyWithHttpInfo(couponCompanyId) {
      let postBody = null;

      // verify the required parameter 'couponCompanyId' is set
      if (couponCompanyId === undefined || couponCompanyId === null) {
        throw new Error("Missing the required parameter 'couponCompanyId' when calling deleteCouponCompany");
      }


      let pathParams = {
        'couponCompanyId': couponCompanyId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/couponCompanies/{couponCompanyId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供企業の削除
     * @param {Number} couponCompanyId クーポン提供企業の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteCouponCompany(couponCompanyId) {
      return this.deleteCouponCompanyWithHttpInfo(couponCompanyId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポンの詳細
     * @param {Number} couponId クーポンの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CouponDetail} and HTTP response
     */
    getCouponWithHttpInfo(couponId) {
      let postBody = null;

      // verify the required parameter 'couponId' is set
      if (couponId === undefined || couponId === null) {
        throw new Error("Missing the required parameter 'couponId' when calling getCoupon");
      }


      let pathParams = {
        'couponId': couponId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = CouponDetail;

      return this.apiClient.callApi(
        '/coupons/{couponId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポンの詳細
     * @param {Number} couponId クーポンの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CouponDetail}
     */
    getCoupon(couponId) {
      return this.getCouponWithHttpInfo(couponId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供ブランドの詳細
     * @param {Number} couponBrandId クーポン提供ブランドの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CouponBrand} and HTTP response
     */
    getCouponBrandWithHttpInfo(couponBrandId) {
      let postBody = null;

      // verify the required parameter 'couponBrandId' is set
      if (couponBrandId === undefined || couponBrandId === null) {
        throw new Error("Missing the required parameter 'couponBrandId' when calling getCouponBrand");
      }


      let pathParams = {
        'couponBrandId': couponBrandId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = CouponBrand;

      return this.apiClient.callApi(
        '/couponBrands/{couponBrandId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供ブランドの詳細
     * @param {Number} couponBrandId クーポン提供ブランドの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CouponBrand}
     */
    getCouponBrand(couponBrandId) {
      return this.getCouponBrandWithHttpInfo(couponBrandId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供ブランドの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CouponBrand>} and HTTP response
     */
    getCouponBrandsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [CouponBrand];

      return this.apiClient.callApi(
        '/couponBrands', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供ブランドの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CouponBrand>}
     */
    getCouponBrands(opts) {
      return this.getCouponBrandsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供企業の一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CouponCompany>} and HTTP response
     */
    getCouponCompaniesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [CouponCompany];

      return this.apiClient.callApi(
        '/couponCompanies', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供企業の一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CouponCompany>}
     */
    getCouponCompanies(opts) {
      return this.getCouponCompaniesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供企業の詳細
     * @param {Number} couponCompanyId クーポン提供企業の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CouponCompany} and HTTP response
     */
    getCouponCompanyWithHttpInfo(couponCompanyId) {
      let postBody = null;

      // verify the required parameter 'couponCompanyId' is set
      if (couponCompanyId === undefined || couponCompanyId === null) {
        throw new Error("Missing the required parameter 'couponCompanyId' when calling getCouponCompany");
      }


      let pathParams = {
        'couponCompanyId': couponCompanyId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = CouponCompany;

      return this.apiClient.callApi(
        '/couponCompanies/{couponCompanyId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供企業の詳細
     * @param {Number} couponCompanyId クーポン提供企業の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CouponCompany}
     */
    getCouponCompany(couponCompanyId) {
      return this.getCouponCompanyWithHttpInfo(couponCompanyId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポンの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CouponSummary>} and HTTP response
     */
    getCouponsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [CouponSummary];

      return this.apiClient.callApi(
        '/coupons', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポンの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CouponSummary>}
     */
    getCoupons(opts) {
      return this.getCouponsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポンの更新
     * @param {Number} couponId クーポンの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CouponUpdateValues} opts.couponUpdateValues 更新するクーポンの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CouponDetail} and HTTP response
     */
    patchCouponWithHttpInfo(couponId, opts) {
      opts = opts || {};
      let postBody = opts['couponUpdateValues'];

      // verify the required parameter 'couponId' is set
      if (couponId === undefined || couponId === null) {
        throw new Error("Missing the required parameter 'couponId' when calling patchCoupon");
      }


      let pathParams = {
        'couponId': couponId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = CouponDetail;

      return this.apiClient.callApi(
        '/coupons/{couponId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポンの更新
     * @param {Number} couponId クーポンの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CouponUpdateValues} opts.couponUpdateValues 更新するクーポンの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CouponDetail}
     */
    patchCoupon(couponId, opts) {
      return this.patchCouponWithHttpInfo(couponId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供ブランドの更新
     * @param {Number} couponBrandId クーポン提供ブランドの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CouponBrandUpdateValues} opts.couponBrandUpdateValues 更新するクーポン提供ブランドの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CouponBrand} and HTTP response
     */
    patchCouponBrandWithHttpInfo(couponBrandId, opts) {
      opts = opts || {};
      let postBody = opts['couponBrandUpdateValues'];

      // verify the required parameter 'couponBrandId' is set
      if (couponBrandId === undefined || couponBrandId === null) {
        throw new Error("Missing the required parameter 'couponBrandId' when calling patchCouponBrand");
      }


      let pathParams = {
        'couponBrandId': couponBrandId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = CouponBrand;

      return this.apiClient.callApi(
        '/couponBrands/{couponBrandId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供ブランドの更新
     * @param {Number} couponBrandId クーポン提供ブランドの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CouponBrandUpdateValues} opts.couponBrandUpdateValues 更新するクーポン提供ブランドの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CouponBrand}
     */
    patchCouponBrand(couponBrandId, opts) {
      return this.patchCouponBrandWithHttpInfo(couponBrandId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供企業の更新
     * @param {Number} couponCompanyId クーポン提供企業の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CouponCompanyUpdateValues} opts.couponCompanyUpdateValues 更新するクーポン提供企業の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CouponCompany} and HTTP response
     */
    patchCouponCompanyWithHttpInfo(couponCompanyId, opts) {
      opts = opts || {};
      let postBody = opts['couponCompanyUpdateValues'];

      // verify the required parameter 'couponCompanyId' is set
      if (couponCompanyId === undefined || couponCompanyId === null) {
        throw new Error("Missing the required parameter 'couponCompanyId' when calling patchCouponCompany");
      }


      let pathParams = {
        'couponCompanyId': couponCompanyId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = CouponCompany;

      return this.apiClient.callApi(
        '/couponCompanies/{couponCompanyId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供企業の更新
     * @param {Number} couponCompanyId クーポン提供企業の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CouponCompanyUpdateValues} opts.couponCompanyUpdateValues 更新するクーポン提供企業の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CouponCompany}
     */
    patchCouponCompany(couponCompanyId, opts) {
      return this.patchCouponCompanyWithHttpInfo(couponCompanyId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポンの追加
     * @param {module:model/NewCoupon} newCoupon 追加するクーポンの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CouponDetail} and HTTP response
     */
    postCouponWithHttpInfo(newCoupon) {
      let postBody = newCoupon;

      // verify the required parameter 'newCoupon' is set
      if (newCoupon === undefined || newCoupon === null) {
        throw new Error("Missing the required parameter 'newCoupon' when calling postCoupon");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = CouponDetail;

      return this.apiClient.callApi(
        '/coupons', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポンの追加
     * @param {module:model/NewCoupon} newCoupon 追加するクーポンの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CouponDetail}
     */
    postCoupon(newCoupon) {
      return this.postCouponWithHttpInfo(newCoupon)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供ブランドの追加
     * @param {module:model/NewCouponBrand} newCouponBrand 追加するクーポン提供ブランドの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CouponBrand} and HTTP response
     */
    postCouponBrandWithHttpInfo(newCouponBrand) {
      let postBody = newCouponBrand;

      // verify the required parameter 'newCouponBrand' is set
      if (newCouponBrand === undefined || newCouponBrand === null) {
        throw new Error("Missing the required parameter 'newCouponBrand' when calling postCouponBrand");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = CouponBrand;

      return this.apiClient.callApi(
        '/couponBrands', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供ブランドの追加
     * @param {module:model/NewCouponBrand} newCouponBrand 追加するクーポン提供ブランドの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CouponBrand}
     */
    postCouponBrand(newCouponBrand) {
      return this.postCouponBrandWithHttpInfo(newCouponBrand)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供企業の追加
     * @param {module:model/NewCouponCompany} newCouponCompany 追加するクーポン提供企業の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CouponCompany} and HTTP response
     */
    postCouponCompanyWithHttpInfo(newCouponCompany) {
      let postBody = newCouponCompany;

      // verify the required parameter 'newCouponCompany' is set
      if (newCouponCompany === undefined || newCouponCompany === null) {
        throw new Error("Missing the required parameter 'newCouponCompany' when calling postCouponCompany");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = CouponCompany;

      return this.apiClient.callApi(
        '/couponCompanies', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供企業の追加
     * @param {module:model/NewCouponCompany} newCouponCompany 追加するクーポン提供企業の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CouponCompany}
     */
    postCouponCompany(newCouponCompany) {
      return this.postCouponCompanyWithHttpInfo(newCouponCompany)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポンの復元
     * @param {Number} couponId クーポンの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putCouponWithHttpInfo(couponId) {
      let postBody = null;

      // verify the required parameter 'couponId' is set
      if (couponId === undefined || couponId === null) {
        throw new Error("Missing the required parameter 'couponId' when calling putCoupon");
      }


      let pathParams = {
        'couponId': couponId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/coupons/{couponId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポンの復元
     * @param {Number} couponId クーポンの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putCoupon(couponId) {
      return this.putCouponWithHttpInfo(couponId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供ブランドの復元
     * @param {Number} couponBrandId クーポン提供ブランドの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putCouponBrandWithHttpInfo(couponBrandId) {
      let postBody = null;

      // verify the required parameter 'couponBrandId' is set
      if (couponBrandId === undefined || couponBrandId === null) {
        throw new Error("Missing the required parameter 'couponBrandId' when calling putCouponBrand");
      }


      let pathParams = {
        'couponBrandId': couponBrandId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/couponBrands/{couponBrandId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供ブランドの復元
     * @param {Number} couponBrandId クーポン提供ブランドの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putCouponBrand(couponBrandId) {
      return this.putCouponBrandWithHttpInfo(couponBrandId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クーポン提供企業の復元
     * @param {Number} couponCompanyId クーポン提供企業の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putCouponCompanyWithHttpInfo(couponCompanyId) {
      let postBody = null;

      // verify the required parameter 'couponCompanyId' is set
      if (couponCompanyId === undefined || couponCompanyId === null) {
        throw new Error("Missing the required parameter 'couponCompanyId' when calling putCouponCompany");
      }


      let pathParams = {
        'couponCompanyId': couponCompanyId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/couponCompanies/{couponCompanyId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クーポン提供企業の復元
     * @param {Number} couponCompanyId クーポン提供企業の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putCouponCompany(couponCompanyId) {
      return this.putCouponCompanyWithHttpInfo(couponCompanyId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
