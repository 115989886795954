/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The NewMediaArticlePvReport model module.
* @module model/NewMediaArticlePvReport
* @version 1.1.70
*/
export default class NewMediaArticlePvReport {
    /**
    * Constructs a new <code>NewMediaArticlePvReport</code>.
    * @alias module:model/NewMediaArticlePvReport
    * @class
    * @param startDate {Date} 
    * @param endDate {Date} 
    * @param email {String} 
    */

    constructor(startDate, endDate, email) {
        


        this['startDate'] = startDate;this['endDate'] = endDate;this['email'] = email;

        
    }

    /**
    * Constructs a <code>NewMediaArticlePvReport</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NewMediaArticlePvReport} obj Optional instance to populate.
    * @return {module:model/NewMediaArticlePvReport} The populated <code>NewMediaArticlePvReport</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewMediaArticlePvReport();

            
            
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Date} startDate
    */
    startDate = undefined;
    /**
    * @member {Date} endDate
    */
    endDate = undefined;
    /**
    * @member {String} email
    */
    email = undefined;








}


