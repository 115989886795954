/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import ArticleSummary from './ArticleSummary';
import RecommendedArticleCover from './RecommendedArticleCover';
import Timestamp from './Timestamp';





/**
* The RecommendedArticle model module.
* @module model/RecommendedArticle
* @version 1.1.70
*/
export default class RecommendedArticle {
    /**
    * Constructs a new <code>RecommendedArticle</code>.
    * @alias module:model/RecommendedArticle
    * @class
    * @param id {Number} おすすめ記事（の設定枠）の ID（記事の ID は `article.id`）
    * @param article {module:model/ArticleSummary} 
    * @param target {module:model/RecommendedArticle.TargetEnum} 
    * @param order {Number} 
    * @param title {String} おすすめ記事（の設定枠）のタイトル（記事のタイトルは `article.title`）
    * @param cover {module:model/RecommendedArticleCover} 
    * @param publishDatetime {String} おすすめ記事（の設定枠）の公開日時（記事の公開日時は `article.publishDateime`）
    */

    constructor(id, article, target, order, title, cover, publishDatetime) {
        


        this['id'] = id;this['article'] = article;this['target'] = target;this['order'] = order;this['title'] = title;this['cover'] = cover;this['publishDatetime'] = publishDatetime;

        
    }

    /**
    * Constructs a <code>RecommendedArticle</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/RecommendedArticle} obj Optional instance to populate.
    * @return {module:model/RecommendedArticle} The populated <code>RecommendedArticle</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RecommendedArticle();

            
            
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('article')) {
                obj['article'] = ArticleSummary.constructFromObject(data['article']);
            }
            if (data.hasOwnProperty('target')) {
                obj['target'] = ApiClient.convertToType(data['target'], 'String');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = ApiClient.convertToType(data['order'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('cover')) {
                obj['cover'] = RecommendedArticleCover.constructFromObject(data['cover']);
            }
            if (data.hasOwnProperty('publishDatetime')) {
                obj['publishDatetime'] = ApiClient.convertToType(data['publishDatetime'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} createdAt
    */
    createdAt = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;
    /**
    * @member {String} deletedAt
    */
    deletedAt = undefined;
    /**
    * おすすめ記事（の設定枠）の ID（記事の ID は `article.id`）
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {module:model/ArticleSummary} article
    */
    article = undefined;
    /**
    * @member {module:model/RecommendedArticle.TargetEnum} target
    */
    target = undefined;
    /**
    * @member {Number} order
    */
    order = undefined;
    /**
    * おすすめ記事（の設定枠）のタイトル（記事のタイトルは `article.title`）
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {module:model/RecommendedArticleCover} cover
    */
    cover = undefined;
    /**
    * おすすめ記事（の設定枠）の公開日時（記事の公開日時は `article.publishDateime`）
    * @member {String} publishDatetime
    */
    publishDatetime = undefined;






    /**
    * Allowed values for the <code>target</code> property.
    * @enum {String}
    * @readonly
    */
    static TargetEnum = {
    
        /**
         * value: "app"
         * @const
         */
        "app": "app",
    
        /**
         * value: "web"
         * @const
         */
        "web": "web"    
    };



}


