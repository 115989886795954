/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import ImageUpdateValues from './ImageUpdateValues';





/**
* The CreatorscreatorIdThumbnail model module.
* @module model/CreatorscreatorIdThumbnail
* @version 1.1.70
*/
export default class CreatorscreatorIdThumbnail {
    /**
    * Constructs a new <code>CreatorscreatorIdThumbnail</code>.
    * @alias module:model/CreatorscreatorIdThumbnail
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>CreatorscreatorIdThumbnail</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CreatorscreatorIdThumbnail} obj Optional instance to populate.
    * @return {module:model/CreatorscreatorIdThumbnail} The populated <code>CreatorscreatorIdThumbnail</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreatorscreatorIdThumbnail();

            
            
            if (data.hasOwnProperty('image')) {
                obj['image'] = ImageUpdateValues.constructFromObject(data['image']);
            }
        }
        return obj;
    }

    /**
    * @member {module:model/ImageUpdateValues} image
    */
    image = undefined;








}


