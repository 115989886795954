/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The SignUpInfo model module.
* @module model/SignUpInfo
* @version 1.1.70
*/
export default class SignUpInfo {
    /**
    * Constructs a new <code>SignUpInfo</code>.
    * @alias module:model/SignUpInfo
    * @class
    * @param username {String} 
    * @param password {String} 
    */

    constructor(username, password) {
        


        this['username'] = username;this['password'] = password;

        
    }

    /**
    * Constructs a <code>SignUpInfo</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/SignUpInfo} obj Optional instance to populate.
    * @return {module:model/SignUpInfo} The populated <code>SignUpInfo</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SignUpInfo();

            
            
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} username
    */
    username = undefined;
    /**
    * @member {String} password
    */
    password = undefined;








}


