/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import ArticleCoverUpdateValues from './ArticleCoverUpdateValues';
import ArticleThumbnailUpdateValues from './ArticleThumbnailUpdateValues';





/**
* The OutsourcedArticleUpdateValues model module.
* @module model/OutsourcedArticleUpdateValues
* @version 1.1.70
*/
export default class OutsourcedArticleUpdateValues {
    /**
    * Constructs a new <code>OutsourcedArticleUpdateValues</code>.
    * @alias module:model/OutsourcedArticleUpdateValues
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>OutsourcedArticleUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/OutsourcedArticleUpdateValues} obj Optional instance to populate.
    * @return {module:model/OutsourcedArticleUpdateValues} The populated <code>OutsourcedArticleUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OutsourcedArticleUpdateValues();

            
            
            if (data.hasOwnProperty('shared')) {
                obj['shared'] = ApiClient.convertToType(data['shared'], 'Boolean');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('summary')) {
                obj['summary'] = ApiClient.convertToType(data['summary'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('thumbnail')) {
                obj['thumbnail'] = ArticleThumbnailUpdateValues.constructFromObject(data['thumbnail']);
            }
            if (data.hasOwnProperty('cover')) {
                obj['cover'] = ArticleCoverUpdateValues.constructFromObject(data['cover']);
            }
            if (data.hasOwnProperty('publishDatetime')) {
                obj['publishDatetime'] = ApiClient.convertToType(data['publishDatetime'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Boolean} shared
    */
    shared = undefined;
    /**
    * @member {String} comment
    */
    comment = undefined;
    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {String} summary
    */
    summary = undefined;
    /**
    * @member {String} description
    */
    description = undefined;
    /**
    * @member {module:model/ArticleThumbnailUpdateValues} thumbnail
    */
    thumbnail = undefined;
    /**
    * @member {module:model/ArticleCoverUpdateValues} cover
    */
    cover = undefined;
    /**
    * @member {String} publishDatetime
    */
    publishDatetime = undefined;








}


