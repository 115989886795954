/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The OutsourcedArticleCsv model module.
* @module model/OutsourcedArticleCsv
* @version 1.1.70
*/
export default class OutsourcedArticleCsv {
    /**
    * Constructs a new <code>OutsourcedArticleCsv</code>.
    * @alias module:model/OutsourcedArticleCsv
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>OutsourcedArticleCsv</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/OutsourcedArticleCsv} obj Optional instance to populate.
    * @return {module:model/OutsourcedArticleCsv} The populated <code>OutsourcedArticleCsv</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OutsourcedArticleCsv();

            
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('finished')) {
                obj['finished'] = ApiClient.convertToType(data['finished'], 'String');
            }
            if (data.hasOwnProperty('requestedCount')) {
                obj['requestedCount'] = ApiClient.convertToType(data['requestedCount'], 'Number');
            }
            if (data.hasOwnProperty('finishedCount')) {
                obj['finishedCount'] = ApiClient.convertToType(data['finishedCount'], 'Number');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {String} username
    */
    username = undefined;
    /**
    * @member {String} finished
    */
    finished = undefined;
    /**
    * @member {Number} requestedCount
    */
    requestedCount = undefined;
    /**
    * @member {Number} finishedCount
    */
    finishedCount = undefined;
    /**
    * @member {String} createdAt
    */
    createdAt = undefined;








}


