/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import OutsourcedArticle from '../model/OutsourcedArticle';
import OutsourcedArticleCsv from '../model/OutsourcedArticleCsv';
import OutsourcedArticleExternal from '../model/OutsourcedArticleExternal';
import OutsourcedArticleUpdateValues from '../model/OutsourcedArticleUpdateValues';

/**
* OutsourcedArticle service.
* @module api/OutsourcedArticleApi
* @version 1.1.70
*/
export default class OutsourcedArticleApi {

    /**
    * Constructs a new OutsourcedArticleApi. 
    * @alias module:api/OutsourcedArticleApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * 記事編集委託ツールからの記事取得
     * @param {Number} articleId 編集委託記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OutsourcedArticleExternal} and HTTP response
     */
    getOutsourcedArticleWithHttpInfo(articleId) {
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling getOutsourcedArticle");
      }


      let pathParams = {
        'articleId': articleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OutsourcedArticleTool'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = OutsourcedArticleExternal;

      return this.apiClient.callApi(
        '/outsourcedArticles/{articleId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事編集委託ツールからの記事取得
     * @param {Number} articleId 編集委託記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OutsourcedArticleExternal}
     */
    getOutsourcedArticle(articleId) {
      return this.getOutsourcedArticleWithHttpInfo(articleId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 編集委託記事の一覧
     * @param {Object} opts Optional parameters
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @param {Number} opts.articleId 編集委託記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/OutsourcedArticle>} and HTTP response
     */
    getOutsourcedArticlesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage'],
        'articleId': opts['articleId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [OutsourcedArticle];

      return this.apiClient.callApi(
        '/outsourcedArticles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 編集委託記事の一覧
     * @param {Object} opts Optional parameters
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @param {Number} opts.articleId 編集委託記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/OutsourcedArticle>}
     */
    getOutsourcedArticles(opts) {
      return this.getOutsourcedArticlesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 編集委託記事CSVの一覧
     * @param {Number} bulkCreateOutsourcedArticleId 編集委託CSV記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'String'} and HTTP response
     */
    getOutsourcedArticlesCsvWithHttpInfo(bulkCreateOutsourcedArticleId) {
      let postBody = null;

      // verify the required parameter 'bulkCreateOutsourcedArticleId' is set
      if (bulkCreateOutsourcedArticleId === undefined || bulkCreateOutsourcedArticleId === null) {
        throw new Error("Missing the required parameter 'bulkCreateOutsourcedArticleId' when calling getOutsourcedArticlesCsv");
      }


      let pathParams = {
        'bulkCreateOutsourcedArticleId': bulkCreateOutsourcedArticleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = 'String';

      return this.apiClient.callApi(
        '/outsourcedArticlesCsv/{bulkCreateOutsourcedArticleId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 編集委託記事CSVの一覧
     * @param {Number} bulkCreateOutsourcedArticleId 編集委託CSV記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'String'}
     */
    getOutsourcedArticlesCsv(bulkCreateOutsourcedArticleId) {
      return this.getOutsourcedArticlesCsvWithHttpInfo(bulkCreateOutsourcedArticleId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 編集委託CSV記事の一覧
     * @param {Object} opts Optional parameters
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/OutsourcedArticleCsv>} and HTTP response
     */
    getOutsourcedCsvArticlesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [OutsourcedArticleCsv];

      return this.apiClient.callApi(
        '/bulkCreateOutsourcedArticles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 編集委託CSV記事の一覧
     * @param {Object} opts Optional parameters
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/OutsourcedArticleCsv>}
     */
    getOutsourcedCsvArticles(opts) {
      return this.getOutsourcedCsvArticlesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 編集委託記事の更新
     * @param {Number} articleId 編集委託記事の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/OutsourcedArticleUpdateValues} opts.outsourcedArticleUpdateValues 更新する編集委託記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    patchOutsourcedArticleWithHttpInfo(articleId, opts) {
      opts = opts || {};
      let postBody = opts['outsourcedArticleUpdateValues'];

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling patchOutsourcedArticle");
      }


      let pathParams = {
        'articleId': articleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2', 'OutsourcedArticleTool'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/outsourcedArticles/{articleId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 編集委託記事の更新
     * @param {Number} articleId 編集委託記事の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/OutsourcedArticleUpdateValues} opts.outsourcedArticleUpdateValues 更新する編集委託記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    patchOutsourcedArticle(articleId, opts) {
      return this.patchOutsourcedArticleWithHttpInfo(articleId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 編集委託記事の追加
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OutsourcedArticle} and HTTP response
     */
    postOutsourcedArticleWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = OutsourcedArticle;

      return this.apiClient.callApi(
        '/outsourcedArticles', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 編集委託記事の追加
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OutsourcedArticle}
     */
    postOutsourcedArticle() {
      return this.postOutsourcedArticleWithHttpInfo()
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 編集委託CSV記事の一覧
     * @param {Number} count 荷物の編集委託CSV記事数
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OutsourcedArticleCsv} and HTTP response
     */
    postOutsourcedCsvArticlesWithHttpInfo(count) {
      let postBody = null;

      // verify the required parameter 'count' is set
      if (count === undefined || count === null) {
        throw new Error("Missing the required parameter 'count' when calling postOutsourcedCsvArticles");
      }


      let pathParams = {
      };
      let queryParams = {
        'count': count
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = OutsourcedArticleCsv;

      return this.apiClient.callApi(
        '/bulkCreateOutsourcedArticles', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 編集委託CSV記事の一覧
     * @param {Number} count 荷物の編集委託CSV記事数
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OutsourcedArticleCsv}
     */
    postOutsourcedCsvArticles(count) {
      return this.postOutsourcedCsvArticlesWithHttpInfo(count)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
