/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';


/**
* Enum class UserRole.
* @enum {}
* @readonly
*/
export default class UserRole {
    
        /**
         * value: "user"
         * @const
         */
        user = "user";

    
        /**
         * value: "analyst"
         * @const
         */
        analyst = "analyst";

    
        /**
         * value: "viewer"
         * @const
         */
        viewer = "viewer";

    
        /**
         * value: "editor"
         * @const
         */
        editor = "editor";

    
        /**
         * value: "owner"
         * @const
         */
        owner = "owner";

    

    /**
    * Returns a <code>UserRole</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/UserRole} The enum <code>UserRole</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}


