/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import MediumDetail from '../model/MediumDetail';
import MediumItem from '../model/MediumItem';
import MediumItemUpdateValues from '../model/MediumItemUpdateValues';
import MediumList from '../model/MediumList';
import MediumUpdateValues from '../model/MediumUpdateValues';
import NewMedium from '../model/NewMedium';
import NewMediumItem from '../model/NewMediumItem';

/**
* Medium service.
* @module api/MediumApi
* @version 1.1.70
*/
export default class MediumApi {

    /**
    * Constructs a new MediumApi. 
    * @alias module:api/MediumApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * メディアの削除
     * @param {Number} mediumId メディアの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteMediumWithHttpInfo(mediumId) {
      let postBody = null;

      // verify the required parameter 'mediumId' is set
      if (mediumId === undefined || mediumId === null) {
        throw new Error("Missing the required parameter 'mediumId' when calling deleteMedium");
      }


      let pathParams = {
        'mediumId': mediumId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/media/{mediumId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディアの削除
     * @param {Number} mediumId メディアの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteMedium(mediumId) {
      return this.deleteMediumWithHttpInfo(mediumId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * メディアの設定の削除
     * @param {Number} mediumItemId メディアの設定の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteMediumItemWithHttpInfo(mediumItemId) {
      let postBody = null;

      // verify the required parameter 'mediumItemId' is set
      if (mediumItemId === undefined || mediumItemId === null) {
        throw new Error("Missing the required parameter 'mediumItemId' when calling deleteMediumItem");
      }


      let pathParams = {
        'mediumItemId': mediumItemId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/mediumItems/{mediumItemId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディアの設定の削除
     * @param {Number} mediumItemId メディアの設定の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteMediumItem(mediumItemId) {
      return this.deleteMediumItemWithHttpInfo(mediumItemId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * メディアの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MediumList>} and HTTP response
     */
    getMediaWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [MediumList];

      return this.apiClient.callApi(
        '/media', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディアの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MediumList>}
     */
    getMedia(opts) {
      return this.getMediaWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * メディアの詳細
     * @param {Number} mediumId メディアの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediumDetail} and HTTP response
     */
    getMediumWithHttpInfo(mediumId) {
      let postBody = null;

      // verify the required parameter 'mediumId' is set
      if (mediumId === undefined || mediumId === null) {
        throw new Error("Missing the required parameter 'mediumId' when calling getMedium");
      }


      let pathParams = {
        'mediumId': mediumId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = MediumDetail;

      return this.apiClient.callApi(
        '/media/{mediumId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディアの詳細
     * @param {Number} mediumId メディアの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediumDetail}
     */
    getMedium(mediumId) {
      return this.getMediumWithHttpInfo(mediumId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * メディアの設定の詳細
     * @param {Number} mediumItemId メディアの設定の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediumItem} and HTTP response
     */
    getMediumItemWithHttpInfo(mediumItemId) {
      let postBody = null;

      // verify the required parameter 'mediumItemId' is set
      if (mediumItemId === undefined || mediumItemId === null) {
        throw new Error("Missing the required parameter 'mediumItemId' when calling getMediumItem");
      }


      let pathParams = {
        'mediumItemId': mediumItemId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = MediumItem;

      return this.apiClient.callApi(
        '/mediumItems/{mediumItemId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディアの設定の詳細
     * @param {Number} mediumItemId メディアの設定の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediumItem}
     */
    getMediumItem(mediumItemId) {
      return this.getMediumItemWithHttpInfo(mediumItemId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * メディアに関連する設定の一覧
     * @param {Number} mediumId メディアの ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/MediumItem>} and HTTP response
     */
    getMediumMediumItemsWithHttpInfo(mediumId, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'mediumId' is set
      if (mediumId === undefined || mediumId === null) {
        throw new Error("Missing the required parameter 'mediumId' when calling getMediumMediumItems");
      }


      let pathParams = {
        'mediumId': mediumId
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [MediumItem];

      return this.apiClient.callApi(
        '/media/{mediumId}/mediumItems', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディアに関連する設定の一覧
     * @param {Number} mediumId メディアの ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/MediumItem>}
     */
    getMediumMediumItems(mediumId, opts) {
      return this.getMediumMediumItemsWithHttpInfo(mediumId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * メディアの更新
     * @param {Number} mediumId メディアの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/MediumUpdateValues} opts.mediumUpdateValues 更新するメディアの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediumDetail} and HTTP response
     */
    patchMediumWithHttpInfo(mediumId, opts) {
      opts = opts || {};
      let postBody = opts['mediumUpdateValues'];

      // verify the required parameter 'mediumId' is set
      if (mediumId === undefined || mediumId === null) {
        throw new Error("Missing the required parameter 'mediumId' when calling patchMedium");
      }


      let pathParams = {
        'mediumId': mediumId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = MediumDetail;

      return this.apiClient.callApi(
        '/media/{mediumId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディアの更新
     * @param {Number} mediumId メディアの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/MediumUpdateValues} opts.mediumUpdateValues 更新するメディアの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediumDetail}
     */
    patchMedium(mediumId, opts) {
      return this.patchMediumWithHttpInfo(mediumId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * メディアの設定の更新
     * @param {Number} mediumItemId メディアの設定の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/MediumItemUpdateValues} opts.mediumItemUpdateValues 更新するメディアの設定の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediumItem} and HTTP response
     */
    patchMediumItemWithHttpInfo(mediumItemId, opts) {
      opts = opts || {};
      let postBody = opts['mediumItemUpdateValues'];

      // verify the required parameter 'mediumItemId' is set
      if (mediumItemId === undefined || mediumItemId === null) {
        throw new Error("Missing the required parameter 'mediumItemId' when calling patchMediumItem");
      }


      let pathParams = {
        'mediumItemId': mediumItemId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = MediumItem;

      return this.apiClient.callApi(
        '/mediumItems/{mediumItemId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディアの設定の更新
     * @param {Number} mediumItemId メディアの設定の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/MediumItemUpdateValues} opts.mediumItemUpdateValues 更新するメディアの設定の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediumItem}
     */
    patchMediumItem(mediumItemId, opts) {
      return this.patchMediumItemWithHttpInfo(mediumItemId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * メディアの追加
     * @param {module:model/NewMedium} newMedium 追加するメディアの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediumDetail} and HTTP response
     */
    postMediumWithHttpInfo(newMedium) {
      let postBody = newMedium;

      // verify the required parameter 'newMedium' is set
      if (newMedium === undefined || newMedium === null) {
        throw new Error("Missing the required parameter 'newMedium' when calling postMedium");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = MediumDetail;

      return this.apiClient.callApi(
        '/media', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディアの追加
     * @param {module:model/NewMedium} newMedium 追加するメディアの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediumDetail}
     */
    postMedium(newMedium) {
      return this.postMediumWithHttpInfo(newMedium)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * メディアの設定の追加
     * @param {module:model/NewMediumItem} newMediumItem 追加するメディアの設定の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MediumItem} and HTTP response
     */
    postMediumItemWithHttpInfo(newMediumItem) {
      let postBody = newMediumItem;

      // verify the required parameter 'newMediumItem' is set
      if (newMediumItem === undefined || newMediumItem === null) {
        throw new Error("Missing the required parameter 'newMediumItem' when calling postMediumItem");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = MediumItem;

      return this.apiClient.callApi(
        '/mediumItems', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディアの設定の追加
     * @param {module:model/NewMediumItem} newMediumItem 追加するメディアの設定の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MediumItem}
     */
    postMediumItem(newMediumItem) {
      return this.postMediumItemWithHttpInfo(newMediumItem)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * メディアの復元
     * @param {Number} mediumId メディアの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putMediumWithHttpInfo(mediumId) {
      let postBody = null;

      // verify the required parameter 'mediumId' is set
      if (mediumId === undefined || mediumId === null) {
        throw new Error("Missing the required parameter 'mediumId' when calling putMedium");
      }


      let pathParams = {
        'mediumId': mediumId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/media/{mediumId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディアの復元
     * @param {Number} mediumId メディアの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putMedium(mediumId) {
      return this.putMediumWithHttpInfo(mediumId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
