/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NewMediaArticlePvReport from '../model/NewMediaArticlePvReport';

/**
* Report service.
* @module api/ReportApi
* @version 1.1.70
*/
export default class ReportApi {

    /**
    * Constructs a new ReportApi. 
    * @alias module:api/ReportApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * メディア毎の記事PVレポートを作成
     * @param {module:model/NewMediaArticlePvReport} newMediaArticlePvReport 作成するメディア毎の記事PVレポートの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    mediaArticlePvWithHttpInfo(newMediaArticlePvReport) {
      let postBody = newMediaArticlePvReport;

      // verify the required parameter 'newMediaArticlePvReport' is set
      if (newMediaArticlePvReport === undefined || newMediaArticlePvReport === null) {
        throw new Error("Missing the required parameter 'newMediaArticlePvReport' when calling mediaArticlePv");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/mediaArticlePvReport', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * メディア毎の記事PVレポートを作成
     * @param {module:model/NewMediaArticlePvReport} newMediaArticlePvReport 作成するメディア毎の記事PVレポートの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    mediaArticlePv(newMediaArticlePvReport) {
      return this.mediaArticlePvWithHttpInfo(newMediaArticlePvReport)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
