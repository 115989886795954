/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CreatorsThumbnail from './CreatorsThumbnail';
import PersonalityQuizQuestion from './PersonalityQuizQuestion';
import PersonalityQuizResult from './PersonalityQuizResult';





/**
* The NewPersonalityQuiz model module.
* @module model/NewPersonalityQuiz
* @version 1.1.70
*/
export default class NewPersonalityQuiz {
    /**
    * Constructs a new <code>NewPersonalityQuiz</code>.
    * @alias module:model/NewPersonalityQuiz
    * @class
    * @param title {String} 
    * @param description {String} 
    * @param thumbnail {module:model/CreatorsThumbnail} 
    * @param categoryId {Number} 
    * @param publishDatetime {String} 
    * @param questions {Array.<module:model/PersonalityQuizQuestion>} 
    * @param results {Array.<module:model/PersonalityQuizResult>} 
    */

    constructor(title, description, thumbnail, categoryId, publishDatetime, questions, results) {
        


        this['title'] = title;this['description'] = description;this['thumbnail'] = thumbnail;this['categoryId'] = categoryId;this['publishDatetime'] = publishDatetime;this['questions'] = questions;this['results'] = results;

        
    }

    /**
    * Constructs a <code>NewPersonalityQuiz</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NewPersonalityQuiz} obj Optional instance to populate.
    * @return {module:model/NewPersonalityQuiz} The populated <code>NewPersonalityQuiz</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewPersonalityQuiz();

            
            
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('thumbnail')) {
                obj['thumbnail'] = CreatorsThumbnail.constructFromObject(data['thumbnail']);
            }
            if (data.hasOwnProperty('cover')) {
                obj['cover'] = CreatorsThumbnail.constructFromObject(data['cover']);
            }
            if (data.hasOwnProperty('categoryId')) {
                obj['categoryId'] = ApiClient.convertToType(data['categoryId'], 'Number');
            }
            if (data.hasOwnProperty('publishDatetime')) {
                obj['publishDatetime'] = ApiClient.convertToType(data['publishDatetime'], 'String');
            }
            if (data.hasOwnProperty('questions')) {
                obj['questions'] = ApiClient.convertToType(data['questions'], [PersonalityQuizQuestion]);
            }
            if (data.hasOwnProperty('results')) {
                obj['results'] = ApiClient.convertToType(data['results'], [PersonalityQuizResult]);
            }
        }
        return obj;
    }

    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {String} description
    */
    description = undefined;
    /**
    * @member {module:model/CreatorsThumbnail} thumbnail
    */
    thumbnail = undefined;
    /**
    * @member {module:model/CreatorsThumbnail} cover
    */
    cover = undefined;
    /**
    * @member {Number} categoryId
    */
    categoryId = undefined;
    /**
    * @member {String} publishDatetime
    */
    publishDatetime = undefined;
    /**
    * @member {Array.<module:model/PersonalityQuizQuestion>} questions
    */
    questions = undefined;
    /**
    * @member {Array.<module:model/PersonalityQuizResult>} results
    */
    results = undefined;








}


