/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import Cp from './Cp';
import ManagementCategory from './ManagementCategory';
import Timestamp from './Timestamp';





/**
* The MediumSummary model module.
* @module model/MediumSummary
* @version 1.1.70
*/
export default class MediumSummary {
    /**
    * Constructs a new <code>MediumSummary</code>.
    * @alias module:model/MediumSummary
    * @class
    * @param id {Number} 
    * @param cp {module:model/Cp} 
    * @param name {String} 
    * @param logo {String} 
    * @param site {String} 
    */

    constructor(id, cp, name, logo, site) {
        


        this['id'] = id;this['cp'] = cp;this['name'] = name;this['logo'] = logo;this['site'] = site;

        
    }

    /**
    * Constructs a <code>MediumSummary</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/MediumSummary} obj Optional instance to populate.
    * @return {module:model/MediumSummary} The populated <code>MediumSummary</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MediumSummary();

            
            
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('cp')) {
                obj['cp'] = Cp.constructFromObject(data['cp']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('logo')) {
                obj['logo'] = ApiClient.convertToType(data['logo'], 'String');
            }
            if (data.hasOwnProperty('site')) {
                obj['site'] = ApiClient.convertToType(data['site'], 'String');
            }
            if (data.hasOwnProperty('isManga')) {
                obj['isManga'] = ApiClient.convertToType(data['isManga'], 'Boolean');
            }
            if (data.hasOwnProperty('managementCategory')) {
                obj['managementCategory'] = ManagementCategory.constructFromObject(data['managementCategory']);
            }
        }
        return obj;
    }

    /**
    * @member {String} createdAt
    */
    createdAt = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;
    /**
    * @member {String} deletedAt
    */
    deletedAt = undefined;
    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {module:model/Cp} cp
    */
    cp = undefined;
    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} logo
    */
    logo = undefined;
    /**
    * @member {String} site
    */
    site = undefined;
    /**
    * @member {Boolean} isManga
    */
    isManga = undefined;
    /**
    * @member {module:model/ManagementCategory} managementCategory
    */
    managementCategory = undefined;








}


