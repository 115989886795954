/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CreatorscreatorIdThumbnail from './CreatorscreatorIdThumbnail';
import GameLocation from './GameLocation';
import GameStatus from './GameStatus';





/**
* The GameUpdateValues model module.
* @module model/GameUpdateValues
* @version 1.1.70
*/
export default class GameUpdateValues {
    /**
    * Constructs a new <code>GameUpdateValues</code>.
    * @alias module:model/GameUpdateValues
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>GameUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/GameUpdateValues} obj Optional instance to populate.
    * @return {module:model/GameUpdateValues} The populated <code>GameUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GameUpdateValues();

            
            
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('guide')) {
                obj['guide'] = ApiClient.convertToType(data['guide'], 'String');
            }
            if (data.hasOwnProperty('originalLink')) {
                obj['originalLink'] = ApiClient.convertToType(data['originalLink'], 'String');
            }
            if (data.hasOwnProperty('displayInformation')) {
                obj['displayInformation'] = ApiClient.convertToType(data['displayInformation'], 'String');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = GameLocation.constructFromObject(data['location']);
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = ApiClient.convertToType(data['order'], 'Number');
            }
            if (data.hasOwnProperty('publishDatetime')) {
                obj['publishDatetime'] = ApiClient.convertToType(data['publishDatetime'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = GameStatus.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('thumbnail')) {
                obj['thumbnail'] = CreatorscreatorIdThumbnail.constructFromObject(data['thumbnail']);
            }
            if (data.hasOwnProperty('gameCategoryId')) {
                obj['gameCategoryId'] = ApiClient.convertToType(data['gameCategoryId'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {String} description
    */
    description = undefined;
    /**
    * @member {String} guide
    */
    guide = undefined;
    /**
    * @member {String} originalLink
    */
    originalLink = undefined;
    /**
    * @member {String} displayInformation
    */
    displayInformation = undefined;
    /**
    * @member {module:model/GameLocation} location
    */
    location = undefined;
    /**
    * @member {Number} order
    */
    order = undefined;
    /**
    * @member {String} publishDatetime
    */
    publishDatetime = undefined;
    /**
    * @member {module:model/GameStatus} status
    */
    status = undefined;
    /**
    * @member {module:model/CreatorscreatorIdThumbnail} thumbnail
    */
    thumbnail = undefined;
    /**
    * @member {Number} gameCategoryId
    */
    gameCategoryId = undefined;








}


