/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import InlineResponse201 from '../model/InlineResponse201';
import NewPanel from '../model/NewPanel';
import Panel from '../model/Panel';
import PanelUpdateValues from '../model/PanelUpdateValues';

/**
* Panel service.
* @module api/PanelApi
* @version 1.1.70
*/
export default class PanelApi {

    /**
    * Constructs a new PanelApi. 
    * @alias module:api/PanelApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * パネルの削除
     * @param {Number} panelId パネルの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deletePanelWithHttpInfo(panelId) {
      let postBody = null;

      // verify the required parameter 'panelId' is set
      if (panelId === undefined || panelId === null) {
        throw new Error("Missing the required parameter 'panelId' when calling deletePanel");
      }


      let pathParams = {
        'panelId': panelId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/panels/{panelId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * パネルの削除
     * @param {Number} panelId パネルの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deletePanel(panelId) {
      return this.deletePanelWithHttpInfo(panelId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * パネルの詳細
     * @param {Number} panelId パネルの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Panel} and HTTP response
     */
    getPanelWithHttpInfo(panelId) {
      let postBody = null;

      // verify the required parameter 'panelId' is set
      if (panelId === undefined || panelId === null) {
        throw new Error("Missing the required parameter 'panelId' when calling getPanel");
      }


      let pathParams = {
        'panelId': panelId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Panel;

      return this.apiClient.callApi(
        '/panels/{panelId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * パネルの詳細
     * @param {Number} panelId パネルの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Panel}
     */
    getPanel(panelId) {
      return this.getPanelWithHttpInfo(panelId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * パネルの一覧
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Panel>} and HTTP response
     */
    getPanelsWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Panel];

      return this.apiClient.callApi(
        '/panels', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * パネルの一覧
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Panel>}
     */
    getPanels() {
      return this.getPanelsWithHttpInfo()
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * パネルの更新
     * @param {Number} panelId パネルの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/PanelUpdateValues} opts.panelUpdateValues 更新するパネルの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    patchPanelWithHttpInfo(panelId, opts) {
      opts = opts || {};
      let postBody = opts['panelUpdateValues'];

      // verify the required parameter 'panelId' is set
      if (panelId === undefined || panelId === null) {
        throw new Error("Missing the required parameter 'panelId' when calling patchPanel");
      }


      let pathParams = {
        'panelId': panelId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/panels/{panelId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * パネルの更新
     * @param {Number} panelId パネルの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/PanelUpdateValues} opts.panelUpdateValues 更新するパネルの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    patchPanel(panelId, opts) {
      return this.patchPanelWithHttpInfo(panelId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * パネルの追加
     * @param {module:model/NewPanel} newPanel 追加するパネルの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse201} and HTTP response
     */
    postPanelWithHttpInfo(newPanel) {
      let postBody = newPanel;

      // verify the required parameter 'newPanel' is set
      if (newPanel === undefined || newPanel === null) {
        throw new Error("Missing the required parameter 'newPanel' when calling postPanel");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = InlineResponse201;

      return this.apiClient.callApi(
        '/panels', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * パネルの追加
     * @param {module:model/NewPanel} newPanel 追加するパネルの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse201}
     */
    postPanel(newPanel) {
      return this.postPanelWithHttpInfo(newPanel)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
