/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CreatorsThumbnail from './CreatorsThumbnail';





/**
* The NewSeries model module.
* @module model/NewSeries
* @version 1.1.70
*/
export default class NewSeries {
    /**
    * Constructs a new <code>NewSeries</code>.
    * @alias module:model/NewSeries
    * @class
    * @param title {String} 
    * @param creatorId {Number} 
    * @param thumbnail {module:model/CreatorsThumbnail} 
    */

    constructor(title, creatorId, thumbnail) {
        


        this['title'] = title;this['creatorId'] = creatorId;this['thumbnail'] = thumbnail;

        
    }

    /**
    * Constructs a <code>NewSeries</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NewSeries} obj Optional instance to populate.
    * @return {module:model/NewSeries} The populated <code>NewSeries</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewSeries();

            
            
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('creatorId')) {
                obj['creatorId'] = ApiClient.convertToType(data['creatorId'], 'Number');
            }
            if (data.hasOwnProperty('thumbnail')) {
                obj['thumbnail'] = CreatorsThumbnail.constructFromObject(data['thumbnail']);
            }
        }
        return obj;
    }

    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {Number} creatorId
    */
    creatorId = undefined;
    /**
    * @member {module:model/CreatorsThumbnail} thumbnail
    */
    thumbnail = undefined;








}


