/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The Panel model module.
* @module model/Panel
* @version 1.1.70
*/
export default class Panel {
    /**
    * Constructs a new <code>Panel</code>.
    * @alias module:model/Panel
    * @class
    * @param id {Number} 
    * @param name {String} 
    */

    constructor(id, name) {
        


        this['id'] = id;this['name'] = name;

        
    }

    /**
    * Constructs a <code>Panel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Panel} obj Optional instance to populate.
    * @return {module:model/Panel} The populated <code>Panel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Panel();

            
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('positionName')) {
                obj['positionName'] = ApiClient.convertToType(data['positionName'], 'String');
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = ApiClient.convertToType(data['image'], 'String');
            }
            if (data.hasOwnProperty('anchorUrl')) {
                obj['anchorUrl'] = ApiClient.convertToType(data['anchorUrl'], 'String');
            }
            if (data.hasOwnProperty('anchorArticleId')) {
                obj['anchorArticleId'] = ApiClient.convertToType(data['anchorArticleId'], 'Number');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = ApiClient.convertToType(data['order'], 'Number');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} positionName
    */
    positionName = undefined;
    /**
    * @member {String} image
    */
    image = undefined;
    /**
    * @member {String} anchorUrl
    */
    anchorUrl = undefined;
    /**
    * @member {Number} anchorArticleId
    */
    anchorArticleId = undefined;
    /**
    * @member {Number} order
    */
    order = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;








}


