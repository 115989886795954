/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.70
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The Creative model module.
* @module model/Creative
* @version 1.1.70
*/
export default class Creative {
    /**
    * Constructs a new <code>Creative</code>.
    * @alias module:model/Creative
    * @class
    * @param id {Number} 
    */

    constructor(id) {
        


        this['id'] = id;

        
    }

    /**
    * Constructs a <code>Creative</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Creative} obj Optional instance to populate.
    * @return {module:model/Creative} The populated <code>Creative</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Creative();

            
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = ApiClient.convertToType(data['image'], 'String');
            }
            if (data.hasOwnProperty('alt')) {
                obj['alt'] = ApiClient.convertToType(data['alt'], 'String');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
            if (data.hasOwnProperty('color')) {
                obj['color'] = ApiClient.convertToType(data['color'], 'String');
            }
            if (data.hasOwnProperty('testParam')) {
                obj['testParam'] = ApiClient.convertToType(data['testParam'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {String} image
    */
    image = undefined;
    /**
    * @member {String} alt
    */
    alt = undefined;
    /**
    * @member {String} text
    */
    text = undefined;
    /**
    * @member {String} color
    */
    color = undefined;
    /**
    * @member {String} testParam
    */
    testParam = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;








}


